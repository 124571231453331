import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  loading: {
    backgroundColor: "rgba(100,100,100,0)",
  },
  header: {
    fontFamily: "Glegoo, serif",
    fontSize: "3rem",
    textAlign: "center",
    margin: "50px",
  },
  button: {
    fontFamily: "Glegoo, serif",
    fontSize: "2rem",
  },
  red: {
    color: "red",
  },
}));

const EndGame = ({ userWhoWon }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        El juego <span className={classes.red}>terminó</span>.
        {userWhoWon ? (
          <p>
            El ganador es: <span className={classes.red}>{userWhoWon}</span>
          </p>
        ) : (
          ""
        )}
      </div>
      <Button
        className={classes.button}
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => {
          window.location.reload();
        }}
      >
        Presiona para reiniciar.
      </Button>
    </div>
  );
};

export default EndGame;
