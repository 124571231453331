import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import GridLottery from "../../GridLottery";

import { joinRoom, setUserName } from "../../../store/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Glegoo, serif",
    display: "flex",
    justifyContent: "center",
    height: "30vh",
    flexDirection: "column",
    alignItems: "center",
    gap: "30px",
    width: "50%",
    margin: "auto",
  },
  header: {
    fontFamily: "Glegoo, serif",
    fontSize: "3rem",
    textAlign: "center",
  },
  red: {
    color: "red",
  },
}));

export default function UnirseSala() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [usuarioUI, setUsuarioUI] = useState("");
  const [salaNombreUI, setSalaNombreUI] = useState("");
  const [waitingToStart, setWaitingToStart] = useState(false);

  const handleSala = (event) =>
    setSalaNombreUI(event.target.value.toUpperCase());

  const handleUser = (event) => setUsuarioUI(event.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (salaNombreUI !== "" && usuarioUI !== "") {
      setWaitingToStart(true);
      dispatch(setUserName(usuarioUI));
      dispatch(joinRoom({ salaNombre: salaNombreUI, usuario: usuarioUI }));
    }
  };

  return (
    <div>
      {waitingToStart ? (
        <GridLottery />
      ) : (
        <div>
          <p className={classes.header}>
            LOTERÍA <span className={classes.red}>SONORA</span> MEXICANA
          </p>
          <form
            className={classes.root}
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <TextField
              fullWidth
              id="outlined-basic"
              label="Unirse a Sala"
              variant="outlined"
              value={salaNombreUI}
              onChange={handleSala}
              required
            />
            <TextField
              fullWidth
              id="outlined-basic"
              label="Nombre de usuario"
              variant="outlined"
              required
              onChange={handleUser}
            />
            <Button fullWidth type="submit" variant="contained" color="primary">
              Entrar a la Sala
            </Button>
          </form>
        </div>
      )}
    </div>
  );
}
