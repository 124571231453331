import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import GridLottery from "../../GridLottery";
import { createRoom, startGame, setUserName } from "../../../store/actions";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "50%",
    height: "30vh",
    justifyContent: "flex-start",
    margin: "50px auto 0 auto",
    gap: "30px",
    marginTop: "100px",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Glegoo, serif",
  },
  buttonStart: {
    fontFamily: "Glegoo, serif",
    width: "50%",
  },
  form: {
    fontFamily: "Glegoo, serif",
    with: "100%",
  },
  users: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    gap: "30px",
  },
  header: {
    fontFamily: "Glegoo, serif",
    fontSize: "3rem",
    textAlign: "center",
  },
  red: {
    color: "red",
  },
}));

export default function CrearSala() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentRoom = useSelector((state) => state.main.currentRoom);
  const gameStarted = useSelector((state) => state.main.gameStarted);
  const usersList = useSelector((state) => state.main.usersList);
  const [salaNombreUI, setSalaNombreUI] = useState("");

  const handleSalaNombre = (event) =>
    setSalaNombreUI(event.target.value.toUpperCase());

  function handleCreate(e) {
    e.preventDefault();
    if (salaNombreUI !== "") {
      console.log(`Crear sala ${salaNombreUI}`);
      console.log(createRoom({ payload: "sala" }));
      dispatch(createRoom(salaNombreUI));
    }
  }
  const handleInit = (e) => {
    e.preventDefault();
    console.log("Send Init game message");
    dispatch(setUserName("Creador de la sala"));
    dispatch(startGame(salaNombreUI));
  };

  const usersListComponent = () =>
    usersList !== undefined && currentRoom !== ""
      ? usersList.map((name, idx) => <div key={idx}>{name}</div>)
      : "";

  return gameStarted ? (
    <GridLottery />
  ) : (
    <div>
      <p className={classes.header}>
        LOTERÍA <span className={classes.red}>SONORA</span> MEXICANA
      </p>
      <form className={classes.root} autoComplete="off" onSubmit={handleCreate}>
        <TextField
          id="outlined-basic"
          label="Crear una sala"
          variant="outlined"
          value={salaNombreUI}
          onChange={handleSalaNombre}
          required
          fullWidth
        />
        <Button
          disabled={currentRoom !== ""}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
        >
          Crear Sala
        </Button>

        {currentRoom !== "" ? <h3>Sala: {currentRoom}</h3> : ""}
        <h3>Jugadores</h3>
        <div className={classes.users}>{usersListComponent()}</div>
        <Button
          disabled={currentRoom === "" || gameStarted}
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleInit}
        >
          Iniciar juego
        </Button>
      </form>
    </div>
  );
}
