import audio_01_olas from "./audio/audio_01_olas.mp3";
import audio_02_lobos_marinos from "./audio/audio_02_lobos_marinos.mp3";
import audio_03_barco_pirata from "./audio/audio_03_barco_pirata.mp3";
import audio_04_Gaviotas from "./audio/audio_04_Gaviotas.mp3";
import audio_05_delfin from "./audio/audio_05_delfin.mp3";
import audio_06_rio_con_rocas from "./audio/audio_06_rio_con_rocas.mp3";
import audio_07_pajaros from "./audio/audio_07_pajaros.mp3";
import audio_08_viento from "./audio/audio_08_viento.mp3";
import audio_09_vaca from "./audio/audio_09_vaca.mp3";
import audio_10_caballo from "./audio/audio_10_caballo.mp3";
import audio_11_gallo from "./audio/audio_11_gallo.mp3";
import audio_12_grillos from "./audio/audio_12_grillos.mp3";
import audio_13_ranas from "./audio/audio_13_ranas.mp3";
import audio_14_lluvia from "./audio/audio_14_lluvia.mp3";
import audio_15_trueno from "./audio/audio_15_trueno.mp3";
import audio_16_vochito from "./audio/audio_16_vochito.mp3";
import audio_17_perros from "./audio/audio_17_perros.mp3";
import audio_18_maquina_de_escribir from "./audio/audio_18_maquina_de_escribir.mp3";
import audio_19_camotero from "./audio/audio_19_camotero.mp3";
import audio_20_campana from "./audio/audio_20_campana.mp3";
import audio_21_policia_silbato from "./audio/audio_21_policia_silbato.mp3";
import audio_22_avion from "./audio/audio_22_avion.mp3";
import audio_23_moto from "./audio/audio_23_moto.mp3";
import audio_24_Fogata from "./audio/audio_24_Fogata.mp3";
import audio_25_mosquito from "./audio/audio_25_mosquito.mp3";
import audio_26_banda_de_musicos from "./audio/audio_26_banda_de_musicos.mp3";
import audio_27_piano from "./audio/audio_27_piano.mp3";
import audio_28_sax from "./audio/audio_28_sax.mp3";
import audio_29_telefono from "./audio/audio_29_telefono.mp3";
import audio_30_ballena from "./audio/audio_30_ballena.mp3";
import audio_31_piedras from "./audio/audio_31_piedras.mp3";
import audio_32_ninos from "./audio/audio_32_ninos.mp3";
import audio_33_libelula from "./audio/audio_33_libelula.mp3";
import audio_34_sirena_ambulancia from "./audio/audio_34_sirena_ambulancia.mp3";
import audio_35_television from "./audio/audio_35_television.mp3";
import audio_36_cepillado_de_dientes from "./audio/audio_36_cepillado_de_dientes.mp3";
import audio_37_gallinas from "./audio/audio_37_gallinas.mp3";
import audio_38_regadera_de_agua from "./audio/audio_38_regadera_de_agua.mp3";
import audio_39_gato from "./audio/audio_39_gato.mp3";
import audio_40_violin from "./audio/audio_40_violin.mp3";
import audio_41_botella_rota from "./audio/audio_41_botella_rota.mp3";
import audio_42_bebe_llorando from "./audio/audio_42_bebe_llorando.mp3";
import audio_43_pasos_corriendo from "./audio/audio_43_pasos_corriendo.mp3";
import audio_44_guitarra from "./audio/audio_44_guitarra.mp3";
import audio_45_puerta from "./audio/audio_45_puerta.mp3";
import audio_46_martillo_madera from "./audio/audio_46_martillo_madera.mp3";
import audio_47_serrucho from "./audio/audio_47_serrucho.mp3";
import audio_48_reloj from "./audio/audio_48_reloj.mp3";
import audio_49_agua_hirviendo from "./audio/audio_49_agua_hirviendo.mp3";
import audio_50_cerillos from "./audio/audio_50_cerillos.mp3";
import audio_51_secadora_de_pelo from "./audio/audio_51_secadora_de_pelo.mp3";
import audio_52_fuegos_artificiales from "./audio/audio_52_fuegos_artificiales.mp3";
import audio_53_licuadora from "./audio/audio_53_licuadora.mp3";
import audio_54_galleta_mordida from "./audio/audio_54_galleta_mordida.mp3";
import audio_55_abejas from "./audio/audio_55_abejas.mp3";
import audio_silence from "./audio/silence.mp3";

export const soundsURL = [
  audio_01_olas,
  audio_02_lobos_marinos,
  audio_03_barco_pirata,
  audio_04_Gaviotas,
  audio_05_delfin,
  audio_06_rio_con_rocas,
  audio_07_pajaros,
  audio_08_viento,
  audio_09_vaca,
  audio_10_caballo,
  audio_11_gallo,
  audio_12_grillos,
  audio_13_ranas,
  audio_14_lluvia,
  audio_15_trueno,
  audio_16_vochito,
  audio_17_perros,
  audio_18_maquina_de_escribir,
  audio_19_camotero,
  audio_20_campana,
  audio_21_policia_silbato,
  audio_22_avion,
  audio_23_moto,
  audio_24_Fogata,
  audio_25_mosquito,
  audio_26_banda_de_musicos,
  audio_27_piano,
  audio_28_sax,
  audio_29_telefono,
  audio_30_ballena,
  audio_31_piedras,
  audio_32_ninos,
  audio_33_libelula,
  audio_34_sirena_ambulancia,
  audio_35_television,
  audio_36_cepillado_de_dientes,
  audio_37_gallinas,
  audio_38_regadera_de_agua,
  audio_39_gato,
  audio_40_violin,
  audio_41_botella_rota,
  audio_42_bebe_llorando,
  audio_43_pasos_corriendo,
  audio_44_guitarra,
  audio_45_puerta,
  audio_46_martillo_madera,
  audio_47_serrucho,
  audio_48_reloj,
  audio_49_agua_hirviendo,
  audio_50_cerillos,
  audio_51_secadora_de_pelo,
  audio_52_fuegos_artificiales,
  audio_53_licuadora,
  audio_54_galleta_mordida,
  audio_55_abejas,
  audio_silence,
];

export const sounds = [
  {
    nombre: "olas",
    imageName: "01_olas.jpg",
    noteName: "C4",
    file: audio_01_olas,
  },
  {
    nombre: "lobos marinos",
    imageName: "02_lobos_marinos.jpg",
    noteName: "C#4",
    file: audio_02_lobos_marinos,
  },
  {
    nombre: "barco pirata",
    imageName: "03_barco_pirata.jpg",
    noteName: "D4",
    file: audio_03_barco_pirata,
  },
  {
    nombre: "gaviotas",
    imageName: "04_Gaviotas.jpg",
    noteName: "D#4",
    file: audio_04_Gaviotas,
  },
  {
    nombre: "delfín",
    imageName: "05_delfin.jpg",
    noteName: "E4",
    file: audio_05_delfin,
  },
  {
    nombre: "río con rocas",
    imageName: "06_rio_con_rocas.jpg",
    noteName: "F4",
    file: audio_06_rio_con_rocas,
  },
  {
    nombre: "pájaros",
    imageName: "07_pajaros.jpg",
    noteName: "F#4",
    file: audio_07_pajaros,
  },
  {
    nombre: "viento",
    imageName: "08_viento.jpg",
    noteName: "G4",
    file: audio_08_viento,
  },
  {
    nombre: "vaca",
    imageName: "09_vaca.jpg",
    noteName: "G#4",
    file: audio_09_vaca,
  },
  {
    nombre: "caballo",
    imageName: "10_caballo.jpg",
    noteName: "A4",
    file: audio_10_caballo,
  },
  {
    nombre: "gallo",
    imageName: "11_gallo.jpg",
    noteName: "A#4",
    file: audio_11_gallo,
  },
  {
    nombre: "grillos",
    imageName: "12_grillos.jpg",
    noteName: "B4",
    file: audio_12_grillos,
  },
  {
    nombre: "ranas",
    imageName: "13_ranas.jpg",
    noteName: "C5",
    file: audio_13_ranas,
  },
  {
    nombre: "lluvia",
    imageName: "14_lluvia.jpg",
    noteName: "C#5",
    file: audio_14_lluvia,
  },
  {
    nombre: "trueno",
    imageName: "15_trueno.jpg",
    noteName: "D5",
    file: audio_15_trueno,
  },
  {
    nombre: "vochito",
    imageName: "16_vochito.jpg",
    noteName: "D#5",
    file: audio_16_vochito,
  },
  {
    nombre: "perros",
    imageName: "17_perros.jpg",
    noteName: "E5",
    file: audio_17_perros,
  },
  {
    nombre: "máquina de escribir",
    imageName: "18_maquina_de_escribir.jpg",
    noteName: "F5",
    file: audio_18_maquina_de_escribir,
  },
  {
    nombre: "camotero",
    imageName: "19_camotero.jpg",
    noteName: "F#5",
    file: audio_19_camotero,
  },
  {
    nombre: "campana",
    imageName: "20_campana.jpg",
    noteName: "G5",
    file: audio_20_campana,
  },
  {
    nombre: "policía silbato",
    imageName: "21_policia_silbato.jpg",
    noteName: "G#5",
    file: audio_21_policia_silbato,
  },
  {
    nombre: "avión",
    imageName: "22_avion.jpg",
    noteName: "A5",
    file: audio_22_avion,
  },
  {
    nombre: "moto",
    imageName: "23_moto.jpg",
    noteName: "A#5",
    file: audio_23_moto,
  },
  {
    nombre: "fogata",
    imageName: "24_Fogata.jpg",
    noteName: "B5",
    file: audio_24_Fogata,
  },
  {
    nombre: "mosquito",
    imageName: "25_mosquito.jpg",
    noteName: "C6",
    file: audio_25_mosquito,
  },
  {
    nombre: "banda de músicos",
    imageName: "26_banda_de_musicos.jpg",
    noteName: "C#6",
    file: audio_26_banda_de_musicos,
  },
  {
    nombre: "piano",
    imageName: "27_piano.jpg",
    noteName: "D6",
    file: audio_27_piano,
  },
  {
    nombre: "sax",
    imageName: "28_sax.jpg",
    noteName: "D#6",
    file: audio_28_sax,
  },
  {
    nombre: "teléfono",
    imageName: "29_telefono.jpg",
    noteName: "E6",
    file: audio_29_telefono,
  },
  {
    nombre: "ballena",
    imageName: "30_ballena.jpg",
    noteName: "F6",
    file: audio_30_ballena,
  },
  {
    nombre: "piedras",
    imageName: "31_piedras.jpg",
    noteName: "F#6",
    file: audio_31_piedras,
  },
  {
    nombre: "niños",
    imageName: "32_ninos.jpg",
    noteName: "G6",
    file: audio_32_ninos,
  },
  {
    nombre: "libélula",
    imageName: "33_libelula.jpg",
    noteName: "G#6",
    file: audio_33_libelula,
  },
  {
    nombre: "sirenas ambulancia",
    imageName: "34_sirena_ambulancia.jpg",
    noteName: "A6",
    file: audio_34_sirena_ambulancia,
  },
  {
    nombre: "televisión",
    imageName: "35_television.jpg",
    noteName: "A#6",
    file: audio_35_television,
  },
  {
    nombre: "cepillado de dientes",
    imageName: "36_cepillado_de_dientes.jpg",
    noteName: "B6",
    file: audio_36_cepillado_de_dientes,
  },
  {
    nombre: "gallinas",
    imageName: "37_gallinas.jpg",
    noteName: "C7",
    file: audio_37_gallinas,
  },
  {
    nombre: "regadera de agua",
    imageName: "38_regadera_de_agua.jpg",
    noteName: "C#7",
    file: audio_38_regadera_de_agua,
  },
  {
    nombre: "gato",
    imageName: "39_gato.jpg",
    noteName: "D7",
    file: audio_39_gato,
  },
  {
    nombre: "violín",
    imageName: "40_violin.jpg",
    noteName: "D#7",
    file: audio_40_violin,
  },
  {
    nombre: "botella rota",
    imageName: "41_botella_rota.jpg",
    noteName: "E7",
    file: audio_41_botella_rota,
  },
  {
    nombre: "bebé llorando",
    imageName: "42_bebe_llorando.jpg",
    noteName: "F7",
    file: audio_42_bebe_llorando,
  },
  {
    nombre: "pasos corriendo",
    imageName: "43_pasos_corriendo.jpg",
    noteName: "F#7",
    file: audio_43_pasos_corriendo,
  },
  {
    nombre: "guitarra",
    imageName: "44_guitarra.jpg",
    noteName: "G7",
    file: audio_44_guitarra,
  },
  {
    nombre: "puerta",
    imageName: "45_puerta.jpg",
    noteName: "G#7",
    file: audio_45_puerta,
  },
  {
    nombre: "martillo madera",
    imageName: "46_martillo_madera.jpg",
    noteName: "A7",
    file: audio_46_martillo_madera,
  },
  {
    nombre: "serrucho",
    imageName: "47_serrucho.jpg",
    noteName: "A#7",
    file: audio_47_serrucho,
  },
  {
    nombre: "reloj",
    imageName: "48_reloj.jpg",
    noteName: "B7",
    file: audio_48_reloj,
  },
  {
    nombre: "agua hirviendo",
    imageName: "49_agua_hirviendo.jpg",
    noteName: "C8",
    file: audio_49_agua_hirviendo,
  },
  {
    nombre: "cerillos",
    imageName: "50_cerillos.jpg",
    noteName: "C#8",
    file: audio_50_cerillos,
  },
  {
    nombre: "secadora de pelo",
    imageName: "51_secadora_de_pelo.jpg",
    noteName: "D8",
    file: audio_51_secadora_de_pelo,
  },
  {
    nombre: "fuegos artificiales",
    imageName: "52_fuegos_artificiales.jpg",
    noteName: "D#8",
    file: audio_52_fuegos_artificiales,
  },
  {
    nombre: "licuadora",
    imageName: "53_licuadora.jpg",
    noteName: "E8",
    file: audio_53_licuadora,
  },
  {
    nombre: "galleta mordida",
    imageName: "54_galleta_mordida.jpg",
    noteName: "F8",
    file: audio_54_galleta_mordida,
  },
  {
    nombre: "abejas",
    imageName: "55_abejas.jpg",
    noteName: "F#8",
    file: audio_55_abejas,
  },
  {
    nombre: "silencio",
    imageName: "55_abejas.jpg",
    noteName: "G8",
    file: audio_silence,
  },
];

export const sonidoMaps = sounds.reduce((prev, curr, idx) => {
  prev[idx + 60] = curr.file;
  return prev;
}, {});
