import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",

    alignItems: "center",
  },
  header: {
    fontFamily: "Glegoo, serif",
    fontSize: "3rem",
    textAlign: "center",
  },
  card: {
    height: "90%",
  },
  image: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    height: "70vh",
    // boxShadow: "5px 10px 8px 10px #888888",
  },
  red: {
    color: "red",
  },
  button: {
    width: "30%",
    fontFamily: "Glegoo, serif",
    fontSize: "1.5rem",

    margin: "1rem",
    marginTop: "3rem",
  },
}));

function SelectionScreen() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p className={classes.header}>Selecciona el tipo de juego</p>

      <Button className={classes.button} component={Link} to={"/juego"}>
        Individual
      </Button>

      <Button className={classes.button} component={Link} to={"/configuracion"}>
        Multijugador
      </Button>
    </div>
  );
}

export default SelectionScreen;
