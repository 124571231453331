import { combineReducers } from "redux";
import { sounds, sonidoMaps } from "../../components/hooks/audioFiles";

const initialState = {
  currentRoom: "",
  gameStarted: false,
  userStarted: false,
  endGame: false,
  winInfo: "",
  usersList: [],
  cardsPlayed: [],
  userName: "",
  sessionCardsIds: [],
  currentImageSinger: -1,
  currentGrid: [],
  message: "",
  messageOpen: false,
  isPlaying: false,
  currentSound: 0,
};

function mainState(state = initialState, action) {
  switch (action.type) {
    case "CREATE_ROOM":
      return { ...state, currentRoom: action.payload };
    case "ALERT_MESSAGE":
      return { ...state, message: action.payload, messageOpen: true };
    case "CLOSE_MESSAGE":
      return {
        ...state,
        message: "",
        messageOpen: false,
      };
    case "JOIN_ROOM":
      return {
        ...state,
        currentRoom: action.payload.salaNombre,
      };
    case "USER_START":
      return {
        ...state,
        userStarted: true,
        isPlaying: true,
      };
    case "RECEIVE_USER_LIST":
      return { ...state, usersList: action.payload.payload };
    case "RECEIVE_START_GAME":
      return {
        ...state,
        gameStarted: true,
        sessionCardsIds: action.payload.cartas
          .split(",")
          .map((e) => parseInt(e)),
      };
    case "RECIEVE_WIN_GAME":
      return {
        ...state,
        endGame: true,
        winInfo: action.payload.usuario,
      };
    case "SET_END_GAME":
      return { ...state, endGame: true, winInfo: action.payload };
    case "CREATE_GRID":
      return { ...state, currentGrid: action.payload };
    case "SET_USER_NAME":
      return { ...state, userName: action.payload };
    case "SET_SESSION_CARDS_IDS":
      return { ...state, seesionCardsIds: action.payload };
    case "PAUSE":
      return { ...state, isPlaying: false };
    case "PLAY":
      return { ...state, isPlaying: true };
    case "NEXT_SOUND":
      const imageName = action.payload;
      let nameHuman = sounds.find((elem) => elem.imageName === imageName);
      return {
        ...state,
        currentSound: state.currentSound + 1,

        currentImageSinger: imageName,

        cardsPlayed: [...state.cardsPlayed, nameHuman.nombre],
      };
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  main: mainState,
});

export default rootReducer;
