import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {},
  selected: {},
  box: {
    border: "1px solid black",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardImg: {
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      height: "70%",
    },
    [theme.breakpoints.up("lg")]: {
      height: "70%",
    },
  },
  shadow: {
    opacity: "0.1",
  },
}));

export default function CardImage({ identifier, imagePath, selected }) {
  const classes = useStyles();
  return (
    <div
      id={identifier}
      className={classes.box + " " + (selected ? classes.shadow : "")}
    >
      <img id={identifier} className={classes.cardImg} src={imagePath} alt="" />
    </div>
  );
}
