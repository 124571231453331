import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import CardImage from '../Card'
import imagesOriginal from './imageslist'
import {
  winGame,
  playSound,
  userStart,
  pause,
  alertMessage
} from '../../store/actions'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  gameBoard: {
    display: 'grid',
    width: '592px',
    height: '929px',
    margin: 'auto',
    gridTemplateRows: '30% 30% 30%',
    gridTemplateColumns: '30% 30% 30%',
    justifyItems: 'center',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center'
  },
  waitScreen: {
    margin: '20%',
    fontFamily: 'Glegoo, serif',
    fontSize: '3rem',
    textAlign: 'center'
  },
  button: {
    position: 'absolute',
    marginTop: '45vh',
    fontFamily: 'Glegoo, serif',
    fontSize: '2rem'
  },
  red: {
    color: 'red'
  }
}))

const srcImage = process.env.PUBLIC_URL + '/images/jpg/'

export default function GridLottery({ numberCards = 9, sala }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const currentImageSinger = useSelector(
    (state) => state.main.currentImageSinger
  )
  const gameStarted = useSelector((state) => state.main.gameStarted)
  const userName = useSelector((state) => state.main.userName)
  const currentRoom = useSelector((state) => state.main.currentRoom)
  const userStarted = useSelector((state) => state.main.userStarted)

  const [cardsPaths, setCardsPaths] = useState([])
  const [cardsNames, setCardsNames] = useState([])
  const [selected, setSelected] = useState({})

  const numberTrue = (obj) =>
    Object.values(obj).reduce((prev, curr) => (curr ? prev + 1 : prev), 0)

  useEffect(() => {
    let cardImages = []
    let cardIdNames = []
    let selectedObjects = {}
    let images = [...imagesOriginal]
    let count = 0
    while (count < 9) {
      count++
      let elemRemove = Math.floor(Math.random() * images.length)
      cardIdNames.push(images[elemRemove])
      cardImages.push(srcImage + images[elemRemove])
      selectedObjects[images[elemRemove]] = false
      images.splice(elemRemove, 1)
    }
    setSelected(selectedObjects)
    setCardsNames(cardIdNames)
    setCardsPaths(cardImages)
  }, [])

  const handleClick = (event) => {
    if (event.target.id === currentImageSinger) {
      let selNew = { ...selected }
      try {
        selNew[event.target.id] = true
        setSelected(selNew)

        if (numberTrue(selNew) === 9) {
          dispatch(winGame({ salaNombre: currentRoom, usuario: userName }))
          dispatch(pause())
        }
      } catch (error) {
        console.error(error.message)
      }
    } else {
      dispatch(alertMessage('Intenta de nuevo'))
      console.log('Not correct', currentImageSinger, event.target.id)
    }
  }

  const gridCards = (num) =>
    [...Array(num).keys()].map((elem) => {
      return (
        <CardImage
          key={elem}
          identifier={cardsNames[elem]}
          imagePath={cardsPaths[elem]}
          selected={selected[cardsNames[elem]]}
        />
      )
    })

  return (
    <div>
      {gameStarted || sala === 'prueba' ? (
        !(userStarted || sala === 'prueba') ? (
          <Button
            className={classes.button}
            fullWidth
            variant='contained'
            color='primary'
            onClick={() => {
              console.log(currentRoom, userName)
              dispatch(userStart())
              dispatch(playSound(55))
            }}
          >
            Iniciar sonidos
          </Button>
        ) : (
          <div>
            <div className={classes.gameBoard} onClick={handleClick}>
              {gridCards(numberCards)}
            </div>
          </div>
        )
      ) : (
        <p className={classes.waitScreen}>
          Espera a que comience el juego. Sala:{' '}
          <span className={classes.red}>{currentRoom}</span>
        </p>
      )}
    </div>
  )
}
