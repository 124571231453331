import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/MainApp";
import reportWebVitals from "./reportWebVitals";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { purple } from "@material-ui/core/colors";
import { Provider } from "react-redux";
import configureStore from "./store/store";
// import * as serviceWorker from "./serviceWorker";

const store = configureStore();
window.onkeydown = function (e) {
  if (e.keyCode === 8 && e.target === document.body) e.preventDefault();
};
const theme = createMuiTheme({
  palette: {
    primary: {
      main: purple[500],
    },
    secondary: {
      main: "#11cb5f",
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
// serviceWorker.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
