import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-around",
    height: "90vh",
    width: "50%",
    flexDirection: "column",
    alignItems: "center",
    margin: "50px auto 0 auto",
  },
  button: {
    fontFamily: "Glegoo, serif",
    height: "20vh",
  },
}));

export default function Configuracion() {
  const classes = useStyles();
  const history = useHistory();
  const handleRoute = (route) => () => {
    history.push(route);
  };
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Button
        className={classes.button}
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleRoute("crear")}
      >
        Crear Sala
      </Button>
      <Button
        className={classes.button}
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleRoute("unir")}
      >
        Unirse a Sala
      </Button>
      <Button
        className={classes.button}
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleRoute("test")}
      >
        Generar Tarjeta
      </Button>
      <Button
        className={classes.button}
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleRoute("sonido")}
      >
        Sonidos
      </Button>
    </form>
  );
}
