import { createAction } from "redux-act";

export const login = createAction("LOGIN");
export const logout = createAction("LOGOUT");
export const alertMessage = createAction("ALERT_MESSAGE", "payload");
export const closeMessage = createAction("CLOSE_MESSAGE");

export const createRoom = createAction("CREATE_ROOM", "payload");
export const joinRoom = createAction("JOIN_ROOM", "payload");
export const recieveUserList = createAction("RECEIVE_USER_LIST", "payload");

export const startGame = createAction("START_GAME", "payload");
export const userStart = createAction("USER_START");
export const receiveStartGame = createAction("RECEIVE_START_GAME", "payload");
export const pause = createAction("PAUSE");
export const play = createAction("PLAY");

export const winGame = createAction("WIN_GAME", "payload");
export const receiveWinGame = createAction("RECIEVE_WIN_GAME", "payload");
export const setEndGame = createAction("SET_END_GAME", "payload");
export const receiveEndGame = createAction("RECEIVE_END_GAME", "payload");

export const playSound = createAction("PLAY_SOUND", "payload");
export const nextSound = createAction("NEXT_SOUND", "payload");

export const createGrid = createAction("CREATE_GRID", "payload");
export const setUserName = createAction("SET_USER_NAME", "payload");
export const setSessionCardsIds = createAction(
  "SET_SESSION_CARDS_IDS",
  "payload"
);
