import React, { useState } from "react";
import { receiveStartGame, userStart } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { pause, play } from "../../store/actions";
import Fab from "@material-ui/core/Fab";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: "Glegoo, serif",
    fontSize: "3rem",
    textAlign: "center",
  },
  button: {
    fontFamily: "Glegoo, serif",
    fontSize: "2rem",
  },
  red: {
    color: "red",
  },
  playPause: {
    width: "20%",
    margin: "auto",
    textAlign: "center",
  },
  // icon: {
  //   width: "200px",
  //   height: "200px",
  // },
  cards: {
    fontFamily: "Glegoo, serif",
    fontSize: "2rem",
    display: "flex",
    width: "50%",
    marginTop: "5vh",
    margin: "auto",
    flexWrap: "wrap",
    gap: "30px",
  },
}));

export default function Dictado() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [playButtonState, setPlayButtonState] = useState(true);
  const cardsPlayed = useSelector((state) => state.main.cardsPlayed);
  const userStarted = useSelector((state) => state.main.userStarted);
  const isPlaying = useSelector((state) => state.main.isPlaying);

  console.log(cardsPlayed);
  const cards = [...cardsPlayed].reverse().join(", ");

  const handleStart = () => {
    dispatch(
      receiveStartGame({
        gameStarted: true,
        cartas: randomNumbres(55).join(","),
      })
    );
    dispatch(userStart());
  };

  const handlePause = () => {
    if (!playButtonState) {
      dispatch(play());
    } else {
      dispatch(pause());
    }
    setPlayButtonState((state) => !state);
  };

  function fillArray(n) {
    let N = n,
      i = 0,
      a = Array(N);
    while (i < N) a[i++] = i - 1;
    return a;
  }

  function randomNumbres(n) {
    var nums = fillArray(n);
    let ranNums = [];
    let i = nums.length;
    let j = 0;
    while (i--) {
      j = Math.floor(Math.random() * (i + 1));
      ranNums.push(nums[j]);
      nums.splice(j, 1);
    }
    return ranNums;
  }

  return (
    <div>
      <p className={classes.header}>
        DICTADO <span className={classes.red}>SONORO</span>
      </p>

      {userStarted ? (
        <div className={classes.playPause}>
          <Fab
            size="large"
            onClick={handlePause}
            className={classes.icon}
            color="primary"
            aria-label="add"
          >
            {playButtonState ? <PauseIcon /> : <PlayArrowIcon />}
          </Fab>
        </div>
      ) : (
        <Button
          disabled={userStarted}
          className={classes.button}
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleStart}
        >
          {userStarted ? "Juego comenzado" : "Iniciar sonidos"}
        </Button>
      )}
      <p className={classes.cards}>{cards}</p>
    </div>
  );
}
