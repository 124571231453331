import io from "socket.io-client";
import { eventChannel } from "redux-saga";
import {
  fork,
  take,
  select,
  call,
  put,
  cancel,
  delay,
} from "redux-saga/effects";
import {
  login,
  logout,
  userStart,
  play,
  pause,
  recieveUserList,
  alertMessage,
  receiveStartGame,
  receiveWinGame,
  nextSound,
} from "../actions";

import { sounds, sonidoMaps } from "../../components/hooks/audioFiles";
import { Sampler } from "tone";

const tone = new Sampler(sonidoMaps, {
  onload: () => {
    console.log("loaded");
  },
}).toDestination();

// const ROUTE = "http://localhost:3001";
const ROUTE = "wss://loteria-servidor-render.onrender.com";
function connect() {
  console.log("Try connect socket");
  const socket = io(ROUTE);
  console.log("After connect send");
  return new Promise((resolve) => {
    socket.on("connect", () => {
      console.log("Connected socket");
      resolve(socket);
    });
  });
}

function subscribe(socket) {
  return eventChannel((emit) => {
    socket.on("ping", (userList) => {
      console.log("pong");
    });
    socket.on("userList", (userList) => {
      console.log("User list");
      console.log(userList);
      emit(recieveUserList({ payload: userList }));
    });
    socket.on("startGame", (e) => {
      console.log("GAME STARTED", e);
      console.log(e);
      emit(receiveStartGame(e));
    });
    socket.on("gameOverWon", (e) => {
      console.log("GAME won", e);
      console.log(e);
      emit(receiveWinGame(e));
      emit(pause());
    });
    socket.on("message", (e) => {
      console.log("Message from server");
      console.log(e);
      emit(alertMessage(e));
    });
    return () => {};
  });
}

function* read(socket) {
  const channel = yield call(subscribe, socket);
  while (true) {
    let action = yield take(channel);
    yield put(action);
  }
}

function* createRoom(socket) {
  while (true) {
    const { payload } = yield take("CREATE_ROOM");
    console.log("create saga", payload);
    socket.emit("createRoom", { payload, senderId: socket.id });
  }
}

function* joinRoom(socket) {
  while (true) {
    const { payload } = yield take("JOIN_ROOM");
    console.log("join room saga", payload);
    socket.emit("joinRoom", { payload, senderId: socket.id });
  }
}

function* startGame(socket) {
  while (true) {
    const { payload } = yield take("START_GAME");
    console.log("start game saga", payload);
    socket.emit("startGame", { payload, senderId: socket.id });
  }
}
function* winGame(socket) {
  while (true) {
    const { payload } = yield take("WIN_GAME");
    console.log("win game saga", payload);
    socket.emit("sendWin", { payload, senderId: socket.id });
  }
}
function* handleIO(socket) {
  yield fork(read, socket);
  yield fork(createRoom, socket);
  yield fork(joinRoom, socket);
  yield fork(startGame, socket);
  yield fork(winGame, socket);
}

function* flow() {
  while (true) {
    yield take(`${login}`);
    const socket = yield call(connect);
    socket.emit("login", socket.id);
    const task = yield fork(handleIO, socket);
    yield take(`${logout}`);
    yield cancel(task);
    socket.emit("logout");
  }
}
export const getState = (state) => state.main;
export const getPlayStatus = (state) => state.main.isPlaying;
export const getCurrentSound = (state) => state.main.currentSound;
export const getSessionCardsIds = (state) => state.main.sessionCardsIds;
export const getEndGame = (state) => state.main.endGame;

function* sequence() {
  let isPlaying;
  let endGame;
  while (true) {
    yield take(`${userStart}`);
    isPlaying = yield select(getPlayStatus);
    const sessionCardsIds = yield select(getSessionCardsIds);
    endGame = yield select(getEndGame);
    tone.triggerAttack(sounds[55].noteName);
    yield delay(8000);
    while (!endGame) {
      while (isPlaying) {
        console.log("loop start");
        console.log(sounds[55].noteName);
        tone.triggerAttack(sounds[55].noteName);
        const currentSound = yield select(getCurrentSound);
        const number = sessionCardsIds[currentSound];
        tone.triggerAttack(sounds[number].noteName);
        yield put(nextSound(sounds[number].imageName));
        yield delay(8000);
        isPlaying = yield select(getPlayStatus);
        console.log("after delay");
      }
      console.log("waiting play");
      yield take(`${play}`);
      console.log("after play");

      endGame = yield select(getEndGame);
      isPlaying = yield select(getPlayStatus);
      console.log({ endGame, isPlaying });
    }
  }
}

export default function* rootSaga() {
  yield fork(flow);
  yield fork(sequence);
}
