import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  card: {
    height: "90%",
  },
  image: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    height: "70vh",
  },
  header: {
    fontFamily: "Glegoo, serif",
    fontSize: "3rem",
    textAlign: "center",
  },
  red: {
    color: "red",
  },
}));

function LandingPage() {
  const classes = useStyles();
  let history = useHistory();

  return (
    <div
      className={classes.root}
      onClick={() => history.push("/configuracion")}
      // onClick={() => history.push("/test")}
    >
      <p className={classes.header}>
        LOTERÍA <span className={classes.red}>SONORA</span> MEXICANA
      </p>

      <div className={classes.image}>
        <img
          alt="ballena saltando"
          className={classes.card}
          src={process.env.PUBLIC_URL + "/images/jpg/30_ballena.jpg"}
        />
        <p>Haz click para comenzar</p>
      </div>
    </div>
  );
}

export default LandingPage;
