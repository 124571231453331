const images = [
  "01_olas.jpg",
  "02_lobos_marinos.jpg",
  "03_barco_pirata.jpg",
  "04_Gaviotas.jpg",
  "05_delfin.jpg",
  "06_rio_con_rocas.jpg",
  "07_pajaros.jpg",
  "08_viento.jpg",
  "09_vaca.jpg",
  "10_caballo.jpg",
  "11_gallo.jpg",
  "12_grillos.jpg",
  "13_ranas.jpg",
  "14_lluvia.jpg",
  "15_trueno.jpg",
  "16_vochito.jpg",
  "17_perros.jpg",
  "18_maquina_de_escribir.jpg",
  "19_camotero.jpg",
  "20_campana.jpg",
  "21_policia_silbato.jpg",
  "22_avion.jpg",
  "23_moto.jpg",
  "24_Fogata.jpg",
  "25_mosquito.jpg",
  "26_banda_de_musicos.jpg",
  "27_piano.jpg",
  "28_sax.jpg",
  "29_telefono.jpg",
  "30_ballena.jpg",
  "31_piedras.jpg",
  "32_ninos.jpg",
  "33_libelula.jpg",
  "34_sirena_ambulancia.jpg",
  "35_television.jpg",
  "36_cepillado_de_dientes.jpg",
  "37_gallinas.jpg",
  "38_regadera_de_agua.jpg",
  "39_gato.jpg",
  "40_violin.jpg",
  "41_botella_rota.jpg",
  "42_bebe_llorando.jpg",
  "43_pasos_corriendo.jpg",
  "44_guitarra.jpg",
  "45_puerta.jpg",
  "46_martillo_madera.jpg",
  "47_serrucho.jpg",
  "48_reloj.jpg",
  "49_agua_hirviendo.jpg",
  "50_cerillos.jpg",
  "51_secadora_de_pelo.jpg",
  "52_fuegos_artificiales.jpg",
  "53_licuadora.jpg",
  "54_galleta_mordida.jpg",
  "55_abejas.jpg",
];
export default images;
