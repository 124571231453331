import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import LandingPage from "../views/LandingPage";
import SelectionScreen from "../views/SelectionScreen";
import Configuracion from "../views/Configuracion";
import CrearSala from "../views/CrearSala";
import UnirseSala from "../views/UnirseSala";
import EndGame from "../views/EndGame";
import GridLottery from "../GridLottery";
import Dictado from "../Dictado";

import { login, logout, closeMessage } from "../../store/actions";
import "./MainApp.css";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function App() {
  const message = useSelector((state) => state.main.message);
  const endGame = useSelector((state) => state.main.endGame);
  const messageOpen = useSelector((state) => state.main.messageOpen);
  const winInfo = useSelector((state) => state.main.winInfo);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    dispatch(closeMessage());
  };

  useEffect(() => {
    dispatch(login());
    return () => {
      dispatch(logout());
    };
  }, [dispatch]);

  return endGame || winInfo !== "" ? (
    <EndGame userWhoWon={winInfo} />
  ) : (
    <div>
      <Snackbar
        open={messageOpen}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert open={messageOpen} onClose={handleClose} severity="warning">
          {message}
        </Alert>
      </Snackbar>
      <Router>
        <Switch>
          <Route exact path="/configuracion">
            <Configuracion />
          </Route>
          <Route exact path="/crear">
            <CrearSala />
          </Route>
          <Route exact path="/unir">
            <UnirseSala />
          </Route>
          <Route exact path="/select">
            <SelectionScreen />
          </Route>
          <Route exact path="/landing">
            <LandingPage />
          </Route>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route exact path="/sonido">
            <Dictado />
          </Route>
          <Route exact path={["/test", "/tarjeta", "/tabla", "tabla"]}>
            <GridLottery sala={"prueba"} />
          </Route>
          {/* <Route path="/">
            <LandingPage />
          </Route> */}
        </Switch>
      </Router>
    </div>
  );
}
